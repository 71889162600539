// Maquina local apiUrl
// http://localhost:80

// Maquina local apiUrl2
// http://localhost:3000



// IP de Maquina Local
// http://192.168.100.40

// IP de Maquina del Trabajoo
// http://192.168.1.26

// IP backend Dairy apiUrl
// https://normal.dairy.com.ar

// IP Dairy apiUrl2
// https://dairy.com.ar

export const apiUrl = 'https://normal.dairy.com.ar';
export const apiUrl2 = 'https://dairy.com.ar';